import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { Section } from '../../styled-components';
import FormProperty from '../forms/properties'

const MainCont = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  top: 3rem;
`

export default () => {
  return (
    <MainCont first>
      

      <Container style={{ margin: '70px .0' }}>
        {/* You can adjust the top and bottom margins above */}
        <FormProperty id="formSearch" withFilters />
      </Container>
    </MainCont>
  )
}